@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans',
  sans-serif;
  background-color: #F5EDE8;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 15px;
}

.container .title-section {
  margin: 80px 0 40px;
}

.container .title-section .logo {
  display: block;
  margin: 20px auto;
  width: 100px;
}

.container .title-section h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.container .title-section p {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.container .info-section {
  background-color: #290F0B;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.form-control {
  width: 100%;
  margin-bottom: 20px;
}

.form-control label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-control select {
  width: 100%;
  padding: 15px;
  border: 1px solid #181516;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}

.form-control select:focus {
  outline: none;
}

.btn {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  background-color: #181516;
  color: #fff;
  border: none;
  outline: none;
}

.site-list {
  margin: 50px 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #181516;
}

.site-list .list-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.site-list h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.site-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.site-list ul li {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5EDE8;
  padding: 20px;
  border-radius: 5px;
}

.status-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 5px; */
  padding: 10px;
  background-color: #ffcc00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sm-btn {
  background-color: #181516;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: none;

}

.disabled-btn {
  background-color: #ccc;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}

.progress-container {
  margin-bottom: 20px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease-in-out;
}

.progress-info {
  margin-top: 10px;
  font-size: 14px;
}

.progress-info p {
  margin: 5px 0;
}